import { ReactNode } from "react";
import CheckIcon from "components/Icons/CheckIcon";

interface PopupProps {
  visible: boolean;
  onClose: () => void;
  children: ReactNode;
}

const Popup: React.FC<PopupProps> = ({ visible, onClose, children }) => {
  if (!visible) return null;

  return (
    <div className="flex items-center absolute top-4 left-0 right-0 w-full max-w-xs m-auto bg-white rounded-2xl p-3">
      <div className="bg-green-600 text-green-200 rounded-full h-6 w-6 flex items-center justify-center">
        <CheckIcon height={16} width={16} />
      </div>
      <p className="text-black-ink text-sm leading-5 font-medium ml-3">
        {children}
      </p>
      <div className="ml-auto pl-3">
        <div className="-mx-1.5 -my-1.5">
          <button
            onClick={onClose}
            className="text-black-ink inline-flex rounded-md p-1.5 hover:bg-grey-950 focus:outline-none active:bg-grey-900 transition ease-in-out duration-150"
            aria-label="Dismiss"
          >
            <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
