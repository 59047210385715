import { FC } from "react";
import classNames from "classnames";
import Link from "next/link";

import { displayNameFromContact } from "@lib/contacts";
import { UserType } from "@lib/data/schemas/user";
import { SanitizedUserType } from "@lib/shared-types";

import { ClientContainerLayoutProps } from "@components/Client/ClientContainerLayout";
import ClientHeaderButtons, {
  ClientHeaderButtonType,
} from "@components/Client/ClientHeaderButtons";
import ClientCheckIcon from "@components/Icons/ClientCheckIcon";

type ClientPageHeaderProps = {
  coach: SanitizedUserType & {
    companyDetails?: UserType["companyDetails"];
    memberAccountIds?: string[];
    orgSlug?: string;
    isMemberResource?: boolean;
  };
  hideCoachAvatar?: boolean;
  hideBorder?: boolean;
  isMemberPage?: boolean;
} & Pick<
  ClientContainerLayoutProps,
  "headerActions" | "onlyThemedIfHasWhiteLabel" | "hideLoginButton"
>;

const ClientPageHeader: FC<ClientPageHeaderProps> = ({
  coach,
  headerActions,
  onlyThemedIfHasWhiteLabel = false,
  hideCoachAvatar = false,
  hideLoginButton = false,
  hideBorder = false,
  isMemberPage = false,
}) => {
  if (!coach) return null;

  const {
    companyName,
    showCompanyLogo,
    companyLogo,
    companyDetails,
    slug,
    avatarURL,
    showLoginButton,
    featureNames = {},
    memberAccountIds,
    orgSlug,
    isMemberResource,
  } = coach;

  const companySlug = isMemberResource || isMemberPage ? orgSlug : slug;

  const multipleMembers = memberAccountIds && memberAccountIds?.length > 1;
  const { companyProfile, whiteLabel } = featureNames;

  const renderCompanyLogo =
    showCompanyLogo &&
    companyLogo?.url &&
    (!onlyThemedIfHasWhiteLabel || whiteLabel);

  const renderCoachAvatar = !renderCompanyLogo && !hideCoachAvatar;
  const renderLoginButton = showLoginButton && !hideLoginButton;

  const renderBorder =
    !hideBorder &&
    (renderCompanyLogo ||
      renderCoachAvatar ||
      renderLoginButton ||
      headerActions);

  const headerButtons: ClientHeaderButtonType[] = [];

  if (renderLoginButton) {
    headerButtons.push({
      icon: <ClientCheckIcon />,
      text: "Client login",
      isIconButton: false,
      href: `/me/${companySlug}/login`,
      heapEventName: "client_login_button_clicked",
      variant: "ghost-theme",
    });
  }

  if (headerActions) {
    headerButtons.push(...headerActions);
  }

  const companyOrMember = companyProfile || isMemberPage;

  const companyLogoMark = companyDetails?.companyLogoMark;
  const image = companyOrMember ? companyLogoMark : avatarURL;
  const hideImage = companyOrMember && !companyLogoMark;
  const name =
    companyOrMember && multipleMembers
      ? companyName
      : displayNameFromContact(coach);

  return (
    <div
      className={classNames(
        "relative h-20 w-full flex items-center print:hidden max-w-screen-md",
        renderBorder && "border-b border-foreground/20"
      )}
    >
      {renderCoachAvatar && (
        <Link href={`/me/${companySlug}`} legacyBehavior>
          <div className="absolute left-0 flex items-center gap-4">
            {!hideImage && image && (
              <img
                alt="Avatar"
                src={image}
                className="rounded-full w-10 h-10 object-cover"
              />
            )}
            <h2 className="text-xl font-medium text-foreground">{name}</h2>
          </div>
        </Link>
      )}
      {renderCompanyLogo && (
        <Link href={`/me/${companySlug}`} legacyBehavior>
          <div
            className="h-16 bg-no-repeat w-full bg-contain bg-background bg-center cursor-pointer"
            style={{
              backgroundImage: `url("${companyLogo.url}")`,
            }}
          />
        </Link>
      )}
      <div className="absolute right-0 flex items-center gap-4">
        <ClientHeaderButtons headerButtons={headerButtons} />
      </div>
    </div>
  );
};

export default ClientPageHeader;
